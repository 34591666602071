import { useRouter } from 'next/router'
import { setCookie } from 'nookies'
import React, { useEffect } from 'react'

const UTMs: React.FC = () => {
  const router = useRouter()

  useEffect(() => {
    if (!router.isReady) return

    const options = { maxAge: 30 * 24 * 60 * 60, path: '/' }
    const utm_page = router.asPath.split('?')[0]
    const utms = [
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_term',
      'utm_content'
    ]

    utms.forEach((param) => {
      if (router.query[param]) {
        setCookie(null, param, String(router.query[param]), options)
      }
    })
    if (utm_page) setCookie(null, 'utm_page', utm_page, options)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady])

  return null
}

export default UTMs
